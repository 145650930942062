body {
  background-color: #2c4762;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 100%;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  gap: 2rem;
}

.fullscreen-bg {
  position: fixed;
  inset: 0;
  background-size: cover;
  background-position: 50% 50%;
  transition: opacity 0.25s ease-out;
  background-image: url("../img/bg/bg-2.jpg");
}

.logo {
  max-width: 90%;
  height: auto;
}

.full-wh {
  position: relative;
  z-index: 0;
}

/* Social Media Bar Styles */
.social-bar {
  display: flex;
  gap: 1.5rem;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 50px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.social-bar a {
  color: #e7e7e5;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-bar a:hover {
  color: #ffffff;
}

/* Modal Styles */
.modal {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in-out;
  z-index: 100;
}

.modal:target {
  visibility: visible;
  opacity: 1;
}

.modal-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #e7e7e5;
  text-decoration: none;
  font-size: 1.5rem;
}

/* Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #e7e7e5;
  font-size: 0.9rem;
}

.form-group input,
.form-group textarea {
  padding: 0.8rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-family: inherit;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #e7e7e5;
}

.submit-btn {
  background: #e7e7e5;
  color: #2c4762;
  border: none;
  padding: 1rem;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.submit-btn:hover {
  opacity: 0.9;
}

@media (min-width: 768px) {
  h1 {
    font-size: 7rem;
    text-shadow: 6px 6px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #e7e7e5;
    padding: 2.5rem;
    color: #e7e7e5;
    display: inline-block;
    margin: 0 auto;
  }

  .logo {
    max-width: 100%;
  }

  .social-bar {
    gap: 2rem;
  }

  .social-bar a {
    font-size: 1.8rem;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 3rem;
    text-shadow: 6px 6px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #e7e7e5;
    padding: 1.25rem;
    color: #e7e7e5;
    display: inline-block;
    margin: 0 auto;
  }

  .modal-content {
    padding: 1rem;
  }
}
